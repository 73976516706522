import React from 'react';
import { Form, Input, Button, Typography, Row, message } from 'antd';
import LogRocket from 'logrocket';
import Cookies from 'js-cookie';
import { observer, inject } from 'mobx-react';
import { PageTransition } from 'next-page-transitions';
import Link from 'next/link';
import Router from 'next/router';

import { loginStepOne, loginStepTwo, getUserByEmailAction } from '@gtintel/platform-api';
import { LoadingSpinner, LoadingSpinner2 } from '@gtintel/platform-atoms';
import { COOKIE_NAMES, ROUTES } from '@gtintel/platform-constants';
import { FeaturesContext } from '@gtintel/platform-context';

import AuthLayout from '../../components/AuthLayout';

const { Title, Text } = Typography;

const OKTA_LOGIN_URL =
  'https://dev-691395.okta.com/home/dev-691395_groundtruthintelligence_1/0oa2cew5hmYW21xR24x7/aln2cf0ray2Lbq9jv4x7';

class Index extends React.Component {
  static async getInitialProps() {
    return {};
  }

  state = {
    step: 1,
    email: '',
    password: '',
    checkForm: true,
    unauthorized: false,
    checkVerifyCode: false,
    DoS_protection: false,
    verifyCode: {
      number_1: null,
      number_2: null,
      number_3: null,
      number_4: null,
      number_5: null,
      number_6: null,
    },
    inputCode2: React.createRef(),
    inputCode3: React.createRef(),
    inputCode4: React.createRef(),
    inputCode5: React.createRef(),
    inputCode6: React.createRef(),
    loading: false,
    urlLoginOkta: '#',
    featureSwitch: {},
  };

  componentDidMount() {
    this.setState({ urlLoginOkta: `${OKTA_LOGIN_URL}` });
    const token = Cookies.get(COOKIE_NAMES.TOKEN);
    if (token) {
      Router.push(ROUTES.SELECT_TYPE_USER_PAGE).catch(() => {});
    }
  }

  static contextType = FeaturesContext;

  handleInputValue = e => {
    switch (e.target.name) {
      case 'email':
        this.setState({ email: e.target.value });
        if (this.state.featureSwitch.sso_login_page === 'on' && e.target.value.endsWith('@gtintel.io')) {
          Router.push(ROUTES.SSO_PAGE).catch(() => {});
        }
        break;
      case 'password':
        this.setState({ password: e.target.value });
        break;
    }
  };

  handleInputVerifyCode = e => {
    switch (e.target.name) {
      case 'verifyCode_1':
        if (e.target.value !== '') {
          this.state.verifyCode.number_1 = e.target.value;
          this.state.inputCode2.current.focus();
        } else {
          this.state.verifyCode.number_1 = null;
        }
        break;
      case 'verifyCode_2':
        if (e.target.value !== '') {
          this.state.verifyCode.number_2 = e.target.value;
          this.state.inputCode3.current.focus();
        } else {
          this.state.verifyCode.number_2 = null;
        }
        break;
      case 'verifyCode_3':
        if (e.target.value !== '') {
          this.state.verifyCode.number_3 = e.target.value;
          this.state.inputCode4.current.focus();
        } else {
          this.state.verifyCode.number_3 = null;
        }
        break;
      case 'verifyCode_4':
        if (e.target.value !== '') {
          this.state.verifyCode.number_4 = e.target.value;
          this.state.inputCode5.current.focus();
        } else {
          this.state.verifyCode.number_4 = null;
        }
        break;
      case 'verifyCode_5':
        if (e.target.value !== '') {
          this.state.verifyCode.number_5 = e.target.value;
          this.state.inputCode6.current.focus();
        } else {
          this.state.verifyCode.number_5 = null;
        }
        break;
      case 'verifyCode_6':
        if (e.target.value !== '') {
          this.state.verifyCode.number_6 = e.target.value;
        } else {
          this.state.verifyCode.number_6 = null;
        }
        break;
    }
  };

  nextStep = () => {
    const { email, password } = this.state;
    if (email.length < 1 || password.length < 8) {
      return;
    }
    const params = {
      email,
      password,
    };

    this.setState({ unauthorized: false, loading: true });
    loginStepOne(params)
      .then(response => {
        this.setState({ loading: false });
        if (response.is2FA) {
          this.setState({ step: 2 });
        } else {
          this.loginSuccess(params.email);
        }
      })
      .catch(e => {
        if (!e) {
          // If you see this error then it probably means you're running
          // localdev but forget to start the backend, or the backend code has
          // a syntax error preventing it from running/responding
          message.error('Could not find server');
          return;
        }

        switch (e.status) {
          case 401:
            this.setState({ unauthorized: true });
            break;
          case 403:
            this.setState({ unauthorized: true });
            break;
          case 500:
            message.error('Internal server error');
            console.error(e);
            break;
          case 503:
            this.setState({ DoS_protection: true });
            break;
          default:
            message.error(`Unknown error (${e.status})`);
            console.error(e);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  signIn = () => {
    const { number_1, number_2, number_3, number_4, number_5, number_6 } = this.state.verifyCode;
    if (
      number_1 === null ||
      number_2 === null ||
      number_3 === null ||
      number_4 === null ||
      number_5 === null ||
      number_6 === null
    ) {
      return;
    }
    const verifyCode = `${number_1}${number_2}${number_3}${number_4}${number_5}${number_6}`;
    if (verifyCode.length !== 6) {
      return;
    }
    this.setState({ loading: true, checkVerifyCode: false });
    const params = {
      email: this.state.email,
      password: this.state.password,
      token: verifyCode,
    };
    loginStepTwo(params)
      .then(() => {
        this.loginSuccess(params.email);
      })
      .catch(() => {
        this.state.verifyCode.number_1 = null;
        this.state.verifyCode.number_2 = null;
        this.state.verifyCode.number_3 = null;
        this.state.verifyCode.number_4 = null;
        this.state.verifyCode.number_5 = null;
        this.state.verifyCode.number_6 = null;
        this.setState({ checkVerifyCode: true, loading: false });
      });
  };

  loginSuccess(email) {
    getUserByEmailAction(email)
      .then(response => {
        LogRocket.identify(String(response[0].id), {
          name: `${response[0].first_name} ${response[0].last_name}`,
          email: response[0].email,
        });
        LogRocket.log('Set LogRocketUserInfo');
        Router.push(ROUTES.SELECT_TYPE_USER_PAGE).catch(() => {});
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { step, unauthorized, checkVerifyCode, DoS_protection } = this.state;
    this.state.featureSwitch = this.context;
    return (
      <AuthLayout title="Login" className="login-page auth-form">
        <Form layout="vertical" size="large">
          <div className="form-min-height">
            {step === 1 ? (
              <>
                <div className="form-header">
                  <Title>{this.state.featureSwitch.sso_login_page === 'on' ? 'First Stage Login' : 'Sign In'}</Title>
                  <Text className="font-weight-light">Please sign in to your account to continue</Text>
                </div>

                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please enter your email' }]}
                  label="Email"
                  className="font-weight-light"
                >
                  <Input
                    data-testid="email-input"
                    placeholder="Enter your email"
                    autoComplete="on"
                    name="email"
                    onChange={this.handleInputValue}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Password!',
                    },
                    { min: 8, message: 'Password is at least 8 characters' },
                  ]}
                  label="Password"
                  className="font-weight-light"
                >
                  <Input
                    data-testid="password-input"
                    autoComplete="on"
                    placeholder="Enter your password"
                    name="password"
                    type="password"
                    onChange={this.handleInputValue}
                  />
                </Form.Item>
                <Form.Item>
                  {unauthorized ? <Text type="danger">* Email or password wrong!</Text> : ''}
                  {DoS_protection ? (
                    <Text type="danger">*You have too many failed login attempts. Please contact GTI Support.</Text>
                  ) : (
                    ''
                  )}
                  <Button data-testid="sign-button" onClick={this.nextStep} type="primary" htmlType="submit">
                    {this.state.loading ? <LoadingSpinner /> : 'SIGN IN'}
                  </Button>
                </Form.Item>

                <Link
                  data-testid="forgot-password-button"
                  href={ROUTES.PASSWORD_RESET}
                  className="font-weight-medium text-dark"
                >
                  Forgot Password?
                </Link>
              </>
            ) : (
              <PageTransition timeout={400} classNames="page-transition-opacity">
                {this.state.loading ? (
                  <LoadingSpinner2 />
                ) : (
                  <>
                    <div className="form-header">
                      <Title>2-Step Verification</Title>
                      <Text className="font-weight-light">
                        Two-factor authentication is enabled on this account. Please enter the verification code below.
                      </Text>
                    </div>

                    <Form.Item
                      label="Enter Verification Code"
                      className="font-weight-light label-text-center"
                      name="verifyCode"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter verification code',
                        },
                      ]}
                    >
                      <Row justify="space-between">
                        <Input
                          maxLength="1"
                          onChange={this.handleInputVerifyCode}
                          name="verifyCode_1"
                          style={{ width: 46 }}
                          required
                        />
                        <Input
                          ref={this.state.inputCode2}
                          maxLength="1"
                          onChange={this.handleInputVerifyCode}
                          name="verifyCode_2"
                          style={{ width: 46 }}
                          required
                        />
                        <Input
                          ref={this.state.inputCode3}
                          maxLength="1"
                          onChange={this.handleInputVerifyCode}
                          name="verifyCode_3"
                          style={{ width: 46 }}
                          required
                        />
                        <Input
                          ref={this.state.inputCode4}
                          maxLength="1"
                          onChange={this.handleInputVerifyCode}
                          name="verifyCode_4"
                          style={{ width: 46 }}
                          required
                        />
                        <Input
                          ref={this.state.inputCode5}
                          maxLength="1"
                          onChange={this.handleInputVerifyCode}
                          name="verifyCode_5"
                          style={{ width: 46 }}
                          required
                        />
                        <Input
                          ref={this.state.inputCode6}
                          maxLength="1"
                          onChange={this.handleInputVerifyCode}
                          name="verifyCode_6"
                          style={{ width: 46 }}
                          required
                        />
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      {checkVerifyCode ? <Text type="danger">* The verification code is incorrect</Text> : ''}
                      <Button onClick={this.signIn} type="primary" htmlType="submit">
                        VERIFY
                      </Button>
                    </Form.Item>
                  </>
                )}
              </PageTransition>
            )}
          </div>
        </Form>
      </AuthLayout>
    );
  }
}

export default inject('authStore')(observer(Index));
